import request from '../utils/request'
import baseUrl from './baseUrl'

// 积分设置-查询
export const getSignIntegerList = (data) => request({
  url: baseUrl + '/signInteger/selectAll',
  method: 'POST',
  data
})
// 修改积分设置
export const modifySignInteger = (data) => request({
  url: baseUrl + '/signInteger/modify',
  method: 'POST',
  data
})
// 连签奖励
export const getVisaBonusList = (data) => request({
    url: baseUrl + '/signContinuous/selectForBack',
    method: 'POST',
    data
  })
//   添加连签奖励
  export const addVisaBonus = (data) => request({
    url: baseUrl + '/signContinuous/add',
    method: 'POST',
    data
  })
//   删除连签奖励
export const deleteVisaBonus = (data) => request({
    url: baseUrl + '/signContinuous/deleteById',
    method: 'POST',
    data
  })