<template>
    <div class="roomList">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>连签奖励列表</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="search">
      <div style="margin-top: 15px">
        <span>连续签到天数:</span>
        <el-select clearable v-model="parmes.days" placeholder="请选择">
          <el-option
            v-for="item in isdayOptions"
            :key="item.label"
            :label="item.label"
            :value="item.label"
          >
          </el-option>
        </el-select>
        <el-button type="primary" @click="getVisaBonusList()">搜索</el-button>
        <el-button
          type="primary"
          @click="addAwad = true"
          v-if="$store.state.powerList.indexOf('sign:continuous:list:add') !== -1"
          >添加奖励</el-button
        >
      </div>
    </div>
      <!-- -------表格----- -->
      <el-table
        :data="tableData"
        style="width: 100%"
        border
      >
        <el-table-column align="center" prop="continuousId" label="ID" >
        </el-table-column>
        <el-table-column align="center" prop="days" label="连续签到天数" >
        </el-table-column>
        <el-table-column align="center" prop="integral" label="奖励积分" >
        </el-table-column>
        <el-table-column align="center" prop="addTime" label="添加时间" >
        </el-table-column>
        <el-table-column align="center" label="操作" width="350">
        <template slot-scope="{ row }">
            <el-button
            size="mini"
            @click="editAwad(row)"
            v-if="
              $store.state.powerList.indexOf('sign:continuous:list:add') !== -1
            "
            type="primary"
            >编辑
          </el-button>
          <el-button
            size="mini"
            @click="deleteAwad(row)"
            v-if="
              $store.state.powerList.indexOf('sign:continuous:list:delete') !== -1
            "
            type="primary"
            >删除
          </el-button>
        </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="parmes.currentPage"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="parmes.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.total"
      >
      </el-pagination>

      <el-dialog
      title="添加/编辑奖励"
      :visible.sync="addAwad"
      @close="dialogClose"
      width="30%"
    >
      <el-form ref="form" label-width="100px">
        <el-form-item label="连续签到天数">
            <el-select clearable v-model="addParmar.days" placeholder="请选择">
          <el-option
            v-for="item in isdayOptions"
            :key="item.label"
            :label="item.label"
            :value="item.label"
          >
          </el-option>
        </el-select>
        </el-form-item>
        <el-form-item label="奖励积分">
            <el-input
          style="width: 300px"
          v-model="addParmar.integral"
          placeholder="请输入奖励积分"
        ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addAwad = false">取 消</el-button>
        <el-button type="primary" @click="onAddCateOk">确 定</el-button>
      </span>
    </el-dialog>

    </div>
  </template>
  <script>
  import {getVisaBonusList,addVisaBonus,deleteVisaBonus} from "@/api/signSetting";
  export default {
    name: "signIntegralSetting",
    data() {
      return {
        tableData:[],
        pagination:{},
        parmes:{
            currentPage:1,
            pageSize:5,
            days:null
        },
        isdayOptions:[],
        addAwad:false,
        addParmar:{
            continuousId:null,
            days:'',
            integral:''
        }
      };
    },
    created() {
      this.getVisaBonusList();
        
      for(let i = 2;i<=30;i++){
        this.isdayOptions.push({label:i})
      }
    },
    methods: {
      dialogClose(){
        this.addParmar.continuousId =null
        this.addParmar.days = ''
        this.addParmar.integral = ''
      },
      async getVisaBonusList() {
        const { data } = await getVisaBonusList(this.parmes);
        this.tableData = data.list;
        this.pagination = data.pagination;
      },
      onChangeSort(row) {
        row.inputVisible = true;
      },
      async onAddCateOk(){
        const { data } = await addVisaBonus(this.addParmar);
        if(data.code == 0){
            this.addAwad = false;
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.getVisaBonusList();
        }else{
            this.$message.error(data.msg);
        }
      },
      editAwad(obj){
        this.addParmar = {...this.addParmar,...obj}
        this.addAwad = true;
        console.log(this.addParmar)
      },
       deleteAwad(row){
        this.$confirm(`是否要删除这个奖励?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const { data } = await deleteVisaBonus({continuousId:row.continuousId});
        if(data.code == 0){
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.getVisaBonusList();
        }else{
            this.$message.error(data.msg);
        }
    })
      },
      handleSizeChange(num) {
        this.parmes.pageSize = num;
        this.getVisaBonusList();
      },
      handleCurrentChange(num) {
        this.parmes.currentPage = num;
        this.getVisaBonusList();
      },
    },
  };
  </script>
  <style lang="less" scoped>
  .roomList {
    .el-breadcrumb {
      height: 35px;
      border-bottom: 1px solid rgb(192, 191, 191);
    }
    .search {
      margin-top: 50px;
      .el-input {
        width: 200px;
      }
      span {
        margin: 0 20px;
      }
      .el-button {
        margin: 0 20px;
      }
    }
    .el-table {
      margin-top: 50px;
    }
    .el-pagination {
      margin-top: 50px;
      float: right;
    }
    .btn {
      margin-top: 50px;
    }
  }
  </style>
  